.dialog {
  position: fixed;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(41, 45, 50, 0.3);
  z-index: 700;
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: flex-start;
}

.pane {
  position: relative;
  margin-top: 92px;
  padding: 0;

  min-width: 622px;
  max-width: 670px;

  font-style: normal;
  font-weight: 400;
  font-size: 0.75rem;
  line-height: 130%;
  width: fit-content;
  color: var(--content-default);
}

.pane.narrow {
  min-width: 500px;
  max-width: 500px;
}

.pane.wide {
  max-width: 1200px;
}

.header {
  padding: 24px;
  border-bottom: 2px solid var(--black-5);
}

.body {
  padding: 24px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  border-bottom: 2px solid var(--black-5);
}

.actions {
  padding: 16px;
  display: flex;
  padding-top: 16px;
  gap: 16px;
}
