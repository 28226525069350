.userNav {
  border-top: 1px solid var(--interactive-border-default);
  margin: 0 16px;
  padding: 24px 0;
  z-index: 10000;
}

.userNav dialog {
  z-index: 10000;
}

.userNav ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.userNav > ul > li {
  height: 40px;
  display: flex;
  flex-direction: row;
  align-items: center;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 136%;
  color: var(--content-default);
  justify-content: space-between;
}

.userNav > ul > li a {
  display: flex;
  flex-direction: row;
  gap: 8px;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  text-decoration: none;
  cursor: pointer;
  user-select: none;
  width: 100%;
  padding: 8px;
  border-radius: 6px;
}

.userNav > ul > li a:hover {
  background-color: var(--interactive-surface-hover);
}

.userNav > ul > li a:active {
  background-color: var(--interactive-surface-active);
  color: var(--interactive-primary-pressed);
}

.userNav > ul > li.open a {
  background-color: var(--interactive-surface-primary);
  color: var(--interactive-primary-action);
}

.userNav ul > li.open ul > li > a {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 136%;
  color: var(--content-default);
}

.userNav ul > li.open ul > li > a:hover {
  background-color: var(--interactive-surface-hover);
}

.userNav ul > li.open ul > li > a:active {
  background-color: var(--interactive-surface-active);
  color: var(--interactive-primary-pressed);
}

.profileImage {
  display: flex;
  width: 40px;
  height: 40px;
  border-radius: 40px;
  background-color: var(--interactive-primary-action);
  color: var(--content-inverted);
  align-items: center;
  justify-content: center;
}

.userName {
  flex: 2;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 110px;
}

.popup {
  bottom: 0;
  left: 240px;
  width: 100%;
  z-index: 10000 !important;
}
