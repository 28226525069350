.layout {
  display: flex;
  flex-direction: column;
  height: 100vh;
  background-color: var(--interactive-surface-primary);
}

.body {
  flex: 1;
  overflow-y: scroll;
}

.outerHeader {
  border-bottom: 1px solid var(--black-10);
}

.header {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  padding: var(--space-md);
}

.title {
  font-style: normal;
  font-weight: 600;
  font-size: 1.25rem;
  line-height: 1.75rem;
  color: var(--neutral-800);
  display: flex;
  gap: 8px;
  align-items: center;
}

.footer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: var(--space-md);
  border-top: 1px solid var(--black-10);
}
