.input {
  padding: 8px 8px 8px 12px;
  border-radius: 8px;
  border: 1px solid var(--interactive-border-default);
  background-color: white;
}

.error {
  border: 1px solid var(--red-80);
}
