.shortCutItem {
  width: 200px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.shortCutItemLabel {
  font-weight: 600;
  font-size: 0.8rem;
}

.shortCutItemDates {
  font-size: 0.7rem;
}

.icon {
  min-width: 16px;
  /*margin-right: 16px;*/
}

.separator {
  display: block;
  background-color: var(--interactive-surface-primary);
  border-top: 1px solid var(--interactive-border-default);
}