.teamFilterList {
  display: flex;
  padding: var(--space-xs) var(--space-md);
  gap: var(--space-xs);
  overflow-x: scroll;
  scrollbar-width: none;
  align-items: center;
}

.teamFilterList::-webkit-scrollbar {
  display: none;
}

.teamFilterItem {
  display: flex;
  align-items: center;
  gap: var(--space-xs);
  background: var(--black-5);
  border-radius: 40px;
  padding: var(--space-xxs) var(--space-sm) var(--space-xxs) var(--space-xs);
  cursor: pointer;
  font-size: 0.75rem;
  font-weight: 600;
  line-height: 132%;
  height: 32px;
  white-space: nowrap;
}

.static {
  padding: var(--space-xxs) var(--space-sm) var(--space-xxs) var(--space-sm);
  cursor: default;
  height: 32px;
}

.label {
  color: var(--black-40);
  font-size: 0.75rem;
  font-weight: 500;
  line-height: 1.25rem;
}

.customTeamIcon path {
  fill: var(--black-60);
}