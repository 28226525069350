.root {
  display: flex;
  flex-direction: row;
  position: relative;
  justify-content: center;
  align-items: center;
}

.container {
  position: relative;
  width: 100vw;
  height: 100vh;
  background: #ffffff;
  overflow-x: scroll;
  scroll-behavior: smooth;
  scroll-padding-top: 130px;
}

.header {
  padding: 9px 40px;
  color: #0e0e2d;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 16px;

  display: flex;
  flex-direction: row;
  align-items: center;
}

.settingsMenu {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.notificationIcon {
  padding: 0 20px;
}

.arrowDownIcon {
  padding-left: 5px;
}

.profileImage {
  width: 36px;
  height: 36px;
  border-radius: 600px;
  background-color: #ce3889;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  font-weight: 300;
}

.dropDownMenu {
  position: relative;
  display: inline-block;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
}

.dropDownMenuContent {
  display: block;
  position: absolute;
  width: 295px;
  height: 173px;
  left: -240px;
  top: 40px;
  background: #ffffff;
  box-shadow: 10px 25px 49px rgba(70, 79, 89, 0.14);
  z-index: 100;
  padding: 25px;

  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: flex-start;
}

/* .dropDownMenuContent:before {
  content: '';
  position: absolute;
  width: 1px;
  height: 15px;
  border: 10px solid transparent;
  border-bottom-color: #FFFFFF;
  right: 5px;
  top: -35px;
} */

.dropDownMenuContent a {
  color: #6d696a;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 160%;
  letter-spacing: 0.004em;
  padding-left: 10px;
}

.dropDownMenuItem {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 185px;
}

.searchField {
  flex: 1;
  margin-right: 10vw;
}

.hidden {
  visibility: hidden;
}

.breadcrumb {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.breadcrumbChevronLeft {
  padding-right: 15px;
}

.headerTitleContainer {
  flex: 1;
}

.adminDashboardDropdown {
  height: 48px;
  width: 100%;
  padding: 5px;
  border-radius: 10px;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 16px;
  cursor: pointer;
}

.demoMode {
  color: transparent;
  text-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
}

.demoMode select {
  color: transparent;
  text-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
}

.greyLine {
  position: absolute;
  top: 70px;
  width: 100%;
  height: 0px;
  border: 2px solid #f1f1f2;
}
