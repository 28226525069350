.pill {
  display: flex;
  align-items: center;
  background-color: var(--black-5);
  padding: 4px 12px;
  border-radius: 40px;
  color: var(--black-40);
  font-size: 0.75rem;
  font-weight: 600;
  line-height: 1rem;
  width: fit-content;
  white-space: nowrap;
}
