.root {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  gap: 8px;
  width: min-content;
  cursor: pointer;
}

.root:focus {
  outline: none;
}

.body {
  display: flex;
  padding: 1px;
  width: 40px;
  height: 24px;
  background: var(--interactive-surface-primary);
  border: 1px solid var(--interactive-border-default);
  border-radius: 16px;
  transition: background 0.2s ease-in-out, border 0.2s ease-in-out;
}

.spacer {
  flex: 0;
  transition: flex 0.2s ease-in-out;
}

.indicator {
  width: 20px;
  height: 20px;
  background: var(--interactive-border-default);
  border-radius: 16px;
  transition: background 0.2s ease-in-out;
}

/*******************
 * States
 *******************/
.loading {
  opacity: 0.5;
}

/* Disabled */
.root.disabled > .body {
  background: var(--interactive-border-disabled);
  border: 1px solid var(--interactive-border-disabled);
}

.root.disabled > .body > .indicator {
  background: var(--interactive-surface-disabled);
}

/* Off */
.root:focus > .body {
  outline: 3px solid var(--interactive-surface-default);
}

/* On */
.body.on {
  background: var(--interactive-primary-action);
  border: 1px solid var(--interactive-primary-action);
}

.body.on > .spacer {
  flex: auto;
  transition: flex 0.2s ease-in-out;
}

.body.on .indicator {
  background: var(--neutral-white);
}

.body.on:hover {
  background: var(--interactive-primary-hover);
}

.body.on:active {
  background: var(--interactive-primary-pressed);
}

.root:focus > .body.on {
  background: var(--interactive-primary-hover);
  outline: 3px solid var(--interactive-border-focused);
}

.body.on:disabled {
  background: var(--interactive-primary-pressed);
}
