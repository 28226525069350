.button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border: none;
  border-radius: 8px;
  font-family: "Airbnb Cereal App";
  font-style: normal;
  font-weight: 500;
  line-height: 140%;
  text-align: center;
  cursor: pointer;
  white-space: nowrap;
}

.childrenWrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: var(--space-xxs);
}

/****************
 * Primary
 ****************/
.primary {
  border: 1px solid var(--interactive-primary-action);
  background: var(--interactive-primary-action);
  color: var(--content-inverted);
}

.primary:hover {
  border: 1px solid var(--interactive-primary-hover);
  background: var(--interactive-primary-hover);
}

.primary:active {
  border: 1px solid var(--interactive-primary-pressed);
  background: var(--interactive-primary-pressed);
}

.primary:focus {
  outline: 3px solid var(--interactive-border-focused);
  background: var(--interactive-primary-hover);
}

.primary:disabled {
  border: 1px solid var(--interactive-primary-disabled);
  background: var(--interactive-primary-disabled);
}

/****************
 * Secondary
 ****************/
.secondary {
  border: 1px solid var(--interactive-primary-action);
  background: var(--content-inverted);
  color: var(--interactive-primary-action);
}

.secondary:hover {
  border: 1px solid var(--interactive-primary-hover);
  color: var(--interactive-primary-hover);
}

.secondary:active {
  border: 1px solid var(--interactive-primary-pressed);
  color: var(--interactive-primary-pressed);
}

.secondary:focus {
  outline: 3px solid var(--interactive-surface-default);
}

.secondary:disabled {
  border: 1px solid var(--interactive-primary-disabled);
  color: var(--interactive-primary-disabled);
}

/****************
 * Tertiary
 ****************/
.tertiary {
  border: 1px solid var(--black-10);
  background: var(--content-inverted);
  color: var(--content-default);
}

.tertiary:hover {
  border: 1px solid var(--black-20);
  color: var(--interactive-primary-hover);
}

.tertiary:active {
  border: 1px solid var(--interactive-primary-pressed);
  color: var(--interactive-primary-pressed);
}

.tertiary:focus {
  outline: 3px solid var(--interactive-surface-default);
}

.tertiary:disabled {
  border: 1px solid var(--interactive-primary-disabled);
  color: var(--interactive-primary-disabled);
}

/****************
 * Text
 ****************/
.text {
  border: 1px solid transparent;
  background: transparent;
  color: var(--interactive-primary-action);
}

.text:hover {
  color: var(--interactive-primary-hover);
}

.text:active {
  color: var(--interactive-primary-pressed);
}

.text:focus {
  outline: 3px solid var(--interactive-surface-default);
}

.text:disabled {
  color: var(--interactive-primary-disabled);
}

/****************
 * Deselected
 ****************/
.deselected {
  font-weight: 400;
  border: 1px solid var(--interactive-border-default);
  background: var(--neutral-white);
  color: var(--content-default);
}

.deselected:hover {
  border: 1px solid var(--interactive-border-hover);
  color: var(--content-active);
}

.deselected:active {
  border: 1px solid var(--interactive-border-active);
  color: var(--content-active);
}

.deselected:focus {
  outline: 3px solid var(--interactive-surface-default);
}

.deselected:disabled {
  color: var(--content-muted);
}

/****************
 * Selected
 ****************/
.selected {
  font-weight: 400;
  border: 1px solid var(--interactive-primary-action);
  background: var(--interactive-surface-default);
  color: var(--content-active);
}

.selected:hover {
  background: var(--interactive-surface-hover);
  color: var(--content-active);
}

.selected:active {
  color: var(--content-active);
}

.selected:active:hover {
  color: var(--content-active);
}

.selected:focus {
  outline: 3px solid var(--interactive-surface-default);
}

.selected:disabled {
  color: var(--content-muted);
}

/* Sizes */
.large {
  gap: 12px;
  padding: 11px 24px;
  font-size: 1rem;
  line-height: 140%;
}

.medium {
  gap: 12px;
  padding: 10px 24px;
  font-size: 0.875rem;
}

.small {
  gap: 10px;
  padding: 5px 16px;
  font-size: 0.875rem;
}

.smallest {
  padding: 0;
  font-size: 0.875rem;
}

/* loading */

.loading > :last-child {
  visibility: hidden;
}

.loader {
  position: absolute;
  width: 16px;
  height: 16px;
}
