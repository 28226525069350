.history,
.empty,
.loading {
  display: flex;
  flex-direction: column;
  gap: var(--space-sm);
  list-style: none;
  background-color: var(--black-5);
  min-width: 292px;
  max-width: 292px;
  width: 292px;
  padding: var(--space-md);
  margin: 0;
  overflow-y: scroll;
}

.empty {
  height: 100%;
  align-items: center;
  text-align: center;
  padding-top: var(--space-xxl);
  color: var(--content-default);
}

.historyGroup {
  display: flex;
  flex-direction: column;
  gap: var(--space-sm);
}

.groupTitle {
  font-size: 1rem;
  font-weight: 600;
  color: var(--black-60);
  width: 100%;
  display: block;
}

.currentSummary {
  font-weight: 500;
  color: var(--interactive-primary-action);
}

.summaryList {
  display: flex;
  flex-direction: column;
  gap: var(--space-sm);
  list-style: none;
  padding: 0;
  color: var(--content-default);
}

.summaryList > li {
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: var(--space-xs);
}

.summaryList > li:hover {
  color: var(--interactive-primary-hover);
}

.trash {
  width: 16px;
  height: 16px;
  flex-shrink: 0;
  flex-grow: 0;
  visibility: hidden;
}

.summaryList > li:hover .trash {
  visibility: visible;
}

.trash path {
  fill: var(--content-default);
}

.confirmationActions {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.confirmationTitle {
  color: var(--content-active);
  font-size: 1.25rem;
  font-weight: 500;
  line-height: 120%;
}

.confirmationSubtitle {
  color: var(--content-default);
  font-size: 0.75rem;
  font-weight: 400;
  line-height: 160%;
}

.confirmSummaryTitle {
  font-size: 1rem;
  font-weight: 500;
}
