.loading {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  flex: 1;
}

.layout {
  display: flex;
  flex: 1;
  height: 100%;
}

.summaryLoader {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: var(--space-sm);
  color: var(--black-160);
  font-weight: 500;
  font-size: 0.875rem;
}

.summary {
  display: flex;
  flex-direction: column;
  gap: var(--space-sm);
  font-size: 0.875rem;
  line-height: 1.25rem;
  overflow-y: scroll;
  padding: var(--space-md);
  width: 100%;
}

.filters {
  display: flex;
  gap: var(--space-xxs);
  align-content: center;
  flex-wrap: wrap;
}

.title {
  color: var(--black-160);
  font-weight: 600;
  font-size: 1.3rem;
}

.body {
  font-weight: 400;
  height: 100%;
}

.optedOut {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: var(--space-xxs);
  font-size: 1.25rem;
  margin-top: var(--space-lg);
  width: 100%;
}

.optedOut > a {
  color: var(--pink-100);
}

.aiButton {
  padding: 5px;
}

.timeoutError {
  display: flex;
  flex-direction: column;
  gap: var(--space-sm);
  font-size: 0.875rem;
  line-height: 1.25rem;
  overflow-y: scroll;
  padding: var(--space-md);
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
}

.content a {
  text-decoration: none;
  color: var(--interactive-primary-action);
  font-weight: 500;
}

.message {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.header {
  font-style: normal;
  font-weight: 500;
  font-size: 1.5rem;
  text-align: center;
  color: var(--content-active);
}

.caption {
  font-style: normal;
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 140%;
  text-align: center;
  color: var(--content-active);
  width: 400px;
}

.summaryBody {
  margin-bottom: var(--space-lg);
}

.crmThemeMenu {
  font-size: 1rem !important;
  font-weight: 400 !important;
  padding: var(--space-md) 0 !important;
}

.crmThemeMenu button {
  padding: var(--space-xs) !important;
}

.crmThemeMenu button > span{
  font-size: 1rem !important;
  padding: var(--space-xxs) !important;
}