.root {
  position: relative;
}

.search {
  width: 100%;
  font-family: "Airbnb Cereal App";
  font-style: normal;
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 130%;
  background: no-repeat left url("/assets/icons/search.svg");
  background-position-x: 0.5rem;
  height: 2rem;
  border: 1px solid var(--interactive-border-default);
  border-radius: 8px;
  padding: 0.5rem 0.5rem 0.5rem 2rem;
  outline: none;
}

.search:hover {
  border-color: var(--interactive-border-hover);
  background-color: var(--interactive-surface-default);
}

.search:focus {
  border-color: var(--interactive-secondary);
}

.clear {
  position: absolute;
  right: 4px;
  top: calc(50% - 12px);
}
