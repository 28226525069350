.root {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: var(--space-xs);
  width: 300px;
}

.customAttributeOption > span[role="button"] {
  border: none;
}

.scrollableSelect dialog {
  max-height: 300px;
  overflow-y: scroll;
}

.tooltip {
  cursor: pointer;
}
