@font-face {
  font-family: "Airbnb Cereal App";
  font-style: normal;
  font-weight: 400;
  src: url("/assets/fonts/airbnb-cereal-font/AirbnbCereal_W_Bk.otf");
}

@font-face {
  font-family: "Airbnb Cereal App";
  font-style: normal;
  font-weight: 500;
  src: url("/assets/fonts/airbnb-cereal-font/AirbnbCereal_W_Md.otf");
}

@font-face {
  font-family: "Airbnb Cereal App";
  font-style: normal;
  font-weight: 700;
  src: url("/assets/fonts/airbnb-cereal-font/AirbnbCereal_W_Bd.otf");
}

:root {
  --space-unit: 24px;

  --space-xxxs: 4px;
  --space-xxs: 6px;
  --space-xs: 8px;
  --space-sm: 16px;
  --space-md: 24px;
  --space-lg: 32px;
  --space-xl: 40px;
  --space-xxl: 48px;

  --max-content-width: 1210px;

  --font-family: "Airbnb Cereal App";

  --max-z-index: 2147483641;
}

html,
body {
  padding: 0;
  margin: 0;
  font-family: var(--font-family);
}

* {
  box-sizing: border-box;
}

a {
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

img {
  max-width: 100%;
  display: block;
}

zing-grid {
  border: none;
  background-color: transparent;

  --zg-icon-color: #ce3889;
  --zg-icon-init-fill: #ce3889;
  --zg-cell-background_sorted: #ffeaf5;
  --zg-head-cell-background_sorted: #ffeaf5;
  --zg-head-cell-justify-content: center;
  --zg-head-cell-icon-margin: 0 3px 0 0;
  --zing-grid-freeze-style-border: 0.01rem solid var(--black-40);
  --zing-grid-freeze-style-box-shadow: none;

  z-index: 0;
}

zg-search {
  display: none;
}

zg-caption {
  display: none;
}

zg-watermark {
  display: none;
}

zg-pager {
  border: none;
  margin-top: 50px;
  max-width: 1450px;
}

zg-row:hover {
  background-color: #f8f8f8;
}

zg-cell {
  border: none;
}

zg-head {
  background-color: transparent;
  z-index: 0;
}

zg-head-cell {
  border-left: none;
  background-color: transparent;
  font-size: 12px;
  font-weight: 400;
  color: #485d67;
}

zg-head-cell > div {
  display: flex;
  justify-content: center;
  align-items: center;
}

zg-row {
  background-color: transparent;
}

zg-icon {
  stroke: #ce3889;
  color: #ce3889;
  fill: #ce3889;
}

zg-tooltip {
  z-index: 100;
  min-width: 75px;
  max-width: 200px;
  margin-left: 50px;
}

.ct-label {
  width: 40px;
}

.icon-green-arrow-up {
  background-image: url("/assets/icons/green-arrow-up.svg");
  width: 25px;
  height: 25px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.icon-green-arrow-down {
  background-image: url("/assets/icons/green-arrow-down.svg");
  width: 16px;
  height: 16px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.icon-red-arrow-up {
  background-image: url("/assets/icons/red-arrow-up.svg");
  width: 16px;
  height: 16px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.icon-red-arrow-down {
  background-image: url("/assets/icons/red-arrow-down.svg");
  width: 25px;
  height: 25px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

button:focus {
  outline: 0;
}

button {
  outline: 0;
}

.uppy-DragDrop-container {
  background-color: #fff2f1;
  border: 1px dashed #ce3889;
  border-radius: 6px;
  color: #ce3889;
  fill: #ce3889;
  cursor: pointer;
}

.ReactModal__Overlay {
  opacity: 1;
  right: 33vw;
  transform: translateX(100px);
  transition:
    opacity 5s linear,
    right 5s ease-out;
}

.ReactModal__Overlay--after-open {
  opacity: 1;
  right: 0;
  transform: translate3d(0%, 0, 0);
}

.ReactModal__Overlay--before-close {
  opacity: 0;
  /*transform: translateX(100px);*/
}

#breakout-ct-chart .ct-series-a .ct-line {
  stroke: #ce3889;
  stroke-width: 2px;
}

#breakout-ct-chart .ct-series-a .ct-area {
  background: #ce3889;
  opacity: 0.3;
}

#breakout-ct-chart .ct-series-a .ct-point {
  stroke: #ce3889;
  stroke-width: 8px;
  cursor: pointer;
}

#breakout-ct-chart .ct-series-b .ct-line {
  stroke: #a3a4af;
  stroke-width: 2px;
  stroke-dasharray: 10px 20px;
}

#breakout-ct-chart .ct-series-b .ct-area {
  display: none;
}

#breakout-ct-chart .ct-series-b .ct-point {
  stroke: #a3a4af;
  stroke-width: 8px;
  cursor: pointer;
}

#breakout-ah-ct-chart .ct-series-a .ct-line {
  stroke: #ce3889;
  stroke-width: 2px;
}

#breakout-ah-ct-chart .ct-series-a .ct-area {
  background: #ce3889;
  opacity: 0.3;
}

#breakout-ah-ct-chart .ct-series-a .ct-point {
  stroke: #ce3889;
  stroke-width: 8px;
  cursor: pointer;
}

#breakout-ah-ct-chart .ct-series-b .ct-line {
  stroke: #a3a4af;
  stroke-width: 2px;
  stroke-dasharray: 10px 20px;
}

#breakout-ah-ct-chart .ct-series-b .ct-area {
  opacity: 0;
  display: none;
}

#breakout-ah-ct-chart .ct-series-b .ct-point {
  stroke: #a3a4af;
  stroke-width: 8px;
  cursor: pointer;
}

#breakout-email-requests-ct-chart .ct-series-a .ct-line {
  stroke: #ce3889;
  stroke-width: 2px;
}

#breakout-email-requests-ct-chart .ct-series-a .ct-area {
  background: #ce3889;
  opacity: 0.3;
}

#breakout-email-requests-ct-chart .ct-series-a .ct-point {
  stroke: #ce3889;
  stroke-width: 8px;
  cursor: pointer;
}

#breakout-email-requests-ct-chart .ct-series-b .ct-line {
  stroke: #a3a4af;
  stroke-width: 2px;
  stroke-dasharray: 10px 20px;
}

#breakout-email-requests-ct-chart .ct-series-b .ct-area {
  opacity: 0;
  display: none;
}

#breakout-email-requests-ct-chart .ct-series-b .ct-point {
  stroke: #a3a4af;
  stroke-width: 8px;
  cursor: pointer;
}

/***********************/
/* DEEP DIVE BAR CHART */
#meetings-deep-dive-internal-ct-chart .ct-series-b .ct-bar {
  stroke: #ce3889;
  stroke-linecap: square;
  cursor: pointer;
}

#meetings-deep-dive-internal-ct-chart .ct-series-a .ct-bar {
  stroke: #07c0a0;
  stroke-linecap: square;
  cursor: pointer;
  opacity: 0.4;
}

#meetings-deep-dive-external-ct-chart .ct-series-b .ct-bar {
  stroke: #ce3889;
  stroke-linecap: square;
  cursor: pointer;
}

#meetings-deep-dive-external-ct-chart .ct-series-b {
  /*transform: translateY(-2px);*/
}

#meetings-deep-dive-external-ct-chart .ct-series-a .ct-bar {
  stroke: #07c0a0;
  stroke-linecap: square;
  cursor: pointer;
  opacity: 0.4;
}

#meetings-deep-dive-ah-internal-ct-chart .ct-series-b .ct-bar {
  stroke: #ce3889;
  stroke-linecap: square;
  cursor: pointer;
}

#meetings-deep-dive-ah-internal-ct-chart .ct-series-a .ct-bar {
  stroke: #07c0a0;
  stroke-linecap: square;
  cursor: pointer;
  opacity: 0.4;
}

#meetings-deep-dive-ah-external-ct-chart .ct-series-b .ct-bar {
  stroke: #ce3889;
  stroke-linecap: square;
  cursor: pointer;
}

#meetings-deep-dive-ah-external-ct-chart .ct-series-a .ct-bar {
  stroke: #07c0a0;
  stroke-linecap: square;
  cursor: pointer;
  opacity: 0.4;
}

#email-requests-deep-dive-internal-ct-chart .ct-series-b .ct-bar {
  stroke: #ce3889;
  stroke-linecap: square;
  cursor: pointer;
}

#email-requests-deep-dive-internal-ct-chart .ct-series-a .ct-bar {
  stroke: #07c0a0;
  stroke-linecap: square;
  cursor: pointer;
  opacity: 0.4;
}

#email-requests-deep-dive-external-ct-chart .ct-series-b .ct-bar {
  stroke: #ce3889;
  stroke-linecap: square;
  cursor: pointer;
}

#email-requests-deep-dive-external-ct-chart .ct-series-a .ct-bar {
  stroke: #07c0a0;
  stroke-linecap: square;
  cursor: pointer;
  opacity: 0.4;
}

#meetings-deep-dive-internal-ct-chart .ct-label {
  cursor: pointer;
}

#meetings-deep-dive-external-ct-chart .ct-label {
  cursor: pointer;
}

#breakouts-ct-chart .ct-series-a .ct-bar {
  stroke: #ce3889;
}

.gridjs-tr .ct-series-b .ct-bar {
  stroke: #ce3889;
  stroke-linecap: round;
  stroke-width: 7px;
}

.gridjs-tr .ct-series-a .ct-bar {
  stroke: #e3e4e7;
  stroke-linecap: round;
  stroke-width: 7px;
}

.gridjs-tr > th {
  background-color: white !important;
  border-bottom: none !important;
  border-right: none !important;
}

td.gridjs-td {
  border-left: none !important;
  border-right: none !important;
  border-top: none !important;
}

.gridjs-thead th {
  border: none !important;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 130%;
  color: #6e6e81;
}

.gridjs-wrapper {
  box-shadow: none !important;
}

/********* React Confirm styling ****************/
.react-confirm-alert-button-group {
  display: flex;
  flex-direction: row;
  justify-content: flex-end !important;
  align-items: center;
}

.react-confirm-alert-button-group button {
  background-color: #ce3889 !important;
}

/******** muiX ********************/
.MuiDateRangePickerDay-rangeIntervalDayHighlight {
  background-color: var(--pink-10) !important;
}

.MuiDateRangePickerDay-day:hover {
  background-color: var(--pink-10) !important;
  opacity: 0.5;
}

.MuiDateRangePickerDay-dayOutsideRangeInterval:hover {
  border: 1px solid var(--pink-30) !important;
}

.MuiDateRangePickerDay-hiddenDayFiller {
  background-color: white !important;
}

.Mui-selected {
  background-color: var(--pink-100) !important;
}

.MuiInputBase-adornedStart > svg {
  margin-right: 5px;
}

.MuiInputBase-adornedStart > input {
  font-size: 0.875rem;
}

.MuiOutlinedInput-notchedOutline {
  border: none !important;
}

.MuiChip-root {
  background-color: unset !important;
}

.MuiChip-root:hover {
  background-color: var(--black-5) !important;
}
.Mui-disabled {
  opacity: 0.3 !important;
}

.MuiDataGrid-columnHeaders {
  background-color: var(--black-5);
}

.MuiPopper-root {
  z-index: 2147483641;
}

#chat-widget-container {
  z-index: 500 !important;
}
