.root {
  width: 24px;
  height: 24px;
  border-radius: 24px;
  user-select: none;
  outline: none;
  display: flex;
  justify-content: center;
  align-items: center;
}

.root:hover {
  background-color: var(--interactive-surface-default);
}

.root.disabled {
  opacity: 0.3;
}

.root:active {
  background-color: var(--interactive-surface-active);
}

.root:focus {
  border: 3px solid var(--interactive-surface-active);
}
