.searchContainer {
  position: relative;
}

.searchPopup {
  padding-left: 0px;
  padding-right: 0px;
  background-color: var(--interactive-surface-primary);
  visibility: hidden;
  white-space: nowrap;
}

/* FIXME: This selector is reaching into the DOM tree of another component,
 *        not ideal. */
.search:has(input:focus) + :has(.searchPopup) > .searchPopup,
.searchPopup:hover {
  visibility: visible;
}

.searchResults {
  list-style: none;
  margin: 0;
  font-size: 0.75rem;
  padding: 0.5rem 0;
}

.searchResults > li {
  padding: 0.5rem 3rem 0.5rem 1rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.searchResults > li:hover {
  cursor: pointer;
}

.searchResultSelected {
  background: no-repeat right url("/assets/icons/check-lg.svg");
  background-position: right 1rem center;
}

.searchResultFocused {
  background-color: var(--interactive-surface-active);
}

.searchResults > li.noResults {
  justify-content: center;
  padding-right: 1rem;
}

.customTeamIcon path {
  fill: var(--black-60);
}
