.root {
}

.icon {
  min-width: 16px;
  margin-right: 16px;
}

.options {
  margin: 0.25rem 0;
  padding: 0;
  user-select: none;
}

.optionGroup {
  margin: 0;
  padding: 0.5rem 0;
  list-style: none;
  display: flex;
  flex-direction: column;
}

.optionGroup > li {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  gap: 8px;
  padding: 10px 0;
  cursor: pointer;
  white-space: nowrap;
  padding: 0.38rem 1rem;
}

.optionGroup > li:hover {
  background-color: var(--neutral-100);
}

.optionGroup > li > label {
  user-select: none;
  cursor: pointer;
  display: flex;
  width: 100%;
  gap: 1rem;
  justify-content: space-between;
  justify-content: flex-start;
}

.selected {
  font-style: normal;
  font-weight: 500;
  line-height: 140%;
}

.disabled {
  opacity: 0.2;
}

.root svg path {
  stroke: var(--interactive-primary-action);
}

.buttonLabel {
  padding: 0;
  list-style: none;
  display: flex;
  gap: 8px;
}

.buttonLabel.multi > li {
  background-color: var(--pink-5);
  border: 1px solid var(--pink-20);
  border-radius: 6px;
  padding: 0 8px;
}
