.input {
  display: none;
}

/*************/
/* Unchecked */
/*************/
.checkbox {
  display: flex;
  justify-content: center;
  width: 16px;
  min-width: 16px;
  height: 16px;
  box-sizing: border-box;
  border: 1px solid var(--content-default);
  background:;
  border-radius: 4px;
  cursor: pointer;
}

.wrapped {
  cursor: pointer;
}

.check {
  align-self: center;
}

.check > path {
  fill: var(--content-inverted);
}

.input:hover + .checkbox,
.checkbox:hover {
  border-color: var(--interactive-primary-action);
}

.input:active + .checkbox,
.checkbox:active {
  border-color: var(--interactive-primary-pressed);
  background-color: var(--interactive-surface-active);
}

.input:disabled + .checkbox {
  border-color: var(--interactive-primary-disabled);
}

/***********/
/* Checked */
/***********/
.input:checked + .checkbox {
  background-color: var(--interactive-primary-action);
  border: 1px solid transparent;
}

.input:checked:not(:disabled):hover + .checkbox,
.input:checked:not(:disabled) + .checkbox:hover {
  background-color: var(--interactive-primary-hover);
}

.input:checked:not(:disabled):active
  + .checkbox
  .input:checked:not(:disabled)
  + .checkbox:active {
  background-color: var(--interactive-primary-pressed);
}

.input:checked:disabled + .checkbox {
  background-color: var(--interactive-primary-disabled);
}

/****************/
/* Half Checked */
/****************/
.halfChecked {
  line-height: 100%;
  width: 100%;
  border-radius: 4px;
  color: white;
  text-align: center;
  background-color: var(--interactive-primary-action);
  border: 1px solid transparent;
}
