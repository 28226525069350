.container {
  position: relative;
  width: 240px;
  height: 100vh;
  background: var(--background-sidebar);
  display: flex;
  flex-direction: column;
}

.logo {
  padding: 20px;
}

.navigation {
  color: var(--content-default);
  width: 230px;
  padding: var(--space-xxs) var(--space-sm);
  display: flex;
  flex-direction: column;
  flex: 2;
  gap: 10px;
  overflow-y: auto;
}

.navigation a,
.navigation a:hover {
  text-decoration: none;
  color: var(--content-default);
}

.navigation ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.navigation li > span,
.navigation li > a {
  display: flex;
  flex-direction: row;
  gap: 8px;
  cursor: pointer;
  user-select: none;
  padding: 8px;
  border-radius: 6px;
}

.navigation li > a > svg {
  align-self: center;
}

.expandButton {
  margin-left: auto;
}

.active {
  background-color: var(--interactive-surface-primary);
  border-radius: 6px;
}

.active > a {
  color: var(--interactive-primary-pressed);
}

/**************************/
/* First level navigation */
/**************************/
.navigation > ul > li {
}

.navigation > ul > li > a:hover {
  background-color: var(--interactive-surface-hover);
}

/**************************/
/* Second level navigation */
/**************************/
.navigation > ul > li > ul > li {
  margin-left: 20px;
}

.navigation > ul > li > ul > li > a:hover {
  background-color: var(--interactive-surface-hover);
}

.navigation > ul > li > ul {
  /* Closed by default */
  display: none;
  border-left: 1px solid var(--neutral-200);
  margin-left: 18px;
}

/* Open */
.navigation > ul > li.open > ul {
  display: block;
}

/**************************/
/* Third level navigation */
/**************************/
.navigation > ul > li > ul > li > ul > li > a {
  display: block;
  padding: 4px 0 4px 24px;
}

.navigation > ul > li > ul > li > ul > li > a:hover {
  background-color: var(--interactive-surface-hover);
  border-radius: 6px;
}

.navigation > ul > li > ul > li > ul {
  padding-top: 1px;
  /* Closed by default */
  display: none;
}

/* Open */
.navigation > ul > li > ul > li.open > ul {
  display: block;
}

/**************************/
/* User navigation        */
/**************************/
.userNav {
  border-top: 1px solid var(--interactive-border-default);
  margin: 0 16px;
  padding: 24px 0;
}

.userNav ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.userNav > ul > li {
  height: 40px;
  display: flex;
  flex-direction: row;
  align-items: center;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 136%;
  color: var(--content-default);
  justify-content: space-between;
}

.userNav > ul > li a {
  display: flex;
  flex-direction: row;
  gap: 8px;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  text-decoration: none;
  cursor: pointer;
  user-select: none;
  width: 100%;
  padding: 8px;
  border-radius: 6px;
}

.userNav > ul > li a:hover {
  background-color: var(--interactive-surface-hover);
}

.userNav > ul > li a:active {
  background-color: var(--interactive-surface-active);
  color: var(--interactive-primary-pressed);
}

.userNav > ul > li.open a {
  background-color: var(--interactive-surface-primary);
  color: var(--interactive-primary-action);
}

.userNav ul > li.open ul > li > a {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 136%;
  color: var(--content-default);
}

.userNav ul > li.open ul > li > a:hover {
  background-color: var(--interactive-surface-hover);
}

.userNav ul > li.open ul > li > a:active {
  background-color: var(--interactive-surface-active);
  color: var(--interactive-primary-pressed);
}

.popup {
  bottom: 0;
  left: 240px;
  width: 100%;
}

.profileImage {
  display: flex;
  width: 40px;
  height: 40px;
  border-radius: 40px;
  background-color: var(--interactive-primary-action);
  color: var(--content-inverted);
  align-items: center;
  justify-content: center;
}

.userName {
  flex: 2;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 110px;
}
