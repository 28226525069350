.root {
  position: absolute;
}

.dialog {
  margin: 8px 0;
  padding-top: 8px;
  padding-bottom: 8px;
  border: none;
  background-color: var(--background-dialog);
  border-radius: 6px;
  box-shadow: 10px 16px 49px rgba(70, 79, 89, 0.2);
  z-index: 10;
}
