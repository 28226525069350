.input {
  box-sizing: border-box;

  /* Auto layout */

  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 11px 24px 11px 16px;
  gap: 10px;

  min-width: 259.5px;
  height: 48px;

  background: #F5F6FA;
  border: 1px solid #E4ECFB;
  border-radius: 10px;

  /* Inside auto layout */

  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;

  font-size: 16px;
}
