.content {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  gap: 16px;
}

.content a {
  text-decoration: none;
  color: var(--interactive-primary-action);
  font-weight: 500;
}

.illustration {
  display: block;
  margin-top: 132px;
}

.message {
  margin-top: 32px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.header {
  font-style: normal;
  font-weight: 500;
  font-size: 1.5rem;
  text-align: center;
  color: var(--content-active);
}

.caption {
  font-style: normal;
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 140%;
  text-align: center;
  color: var(--content-active);
  width: 400px;
}
