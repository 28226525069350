.feedback {
  display: flex;
  flex-direction: column;
  gap: var(--space-sm);
  padding-bottom: var(--space-lg);
}

.feedbackBar {
  display: flex;
  gap: var(--space-sm);
  justify-content: flex-end;
  margin-right: var(--space-md);
}

.moreFeedback {
  display: flex;
  flex-direction: column;
  gap: var(--space-xs);
  border: 1px solid var(--black-10);
  border-radius: 6px;
  padding: var(--space-sm);
  color: var(--content-active);
}

.feedbackOptions {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  list-style: none;
  gap: var(--space-xxs);
  padding: 0;
  margin: 0;
}

.feedbackOther {
  width: 100%;
  display: flex;
  gap: var(--space-xxs);
}

.feedbackOther > :first-child {
  flex: 1;
}

.selected path {
  fill: var(--interactive-primary-pressed);
}
