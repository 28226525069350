.root {
  margin: 0;
  padding: 0;
  line-height: 2rem;
  font-size: 1.75rem;
  font-weight: 700;
  color: var(--black-200);
  min-height: 1rem;
  display: flex;
  align-items: center;
  gap: var(--space-md);
}

.label {
  font-weight: 500;
  color: var(--interactive-primary-action);
  font-size: 1.75rem;
  padding: 0.75rem;
}

.tooltip {
  cursor: pointer;
  display: inline-flex;
  align-items: center;
}