.entitySelect {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: var(--space-sm);
}

.customTeamIcon path {
  fill: var(--black-60);
}

.selectedEmployees {
  list-style: none;
  padding: 0;
  margin: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
}

.selectedEmployees > li {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  padding: 8px;
}

.selectedEmployees > li {
}
