.root {
  z-index: 501;
}

.tooltipContent {
  background-color: var(--neutral-white);
  padding: 16px;
  border-radius: 2px;
  box-shadow: 0px 8px 28px rgba(24, 39, 75, 0.12),
    0px 18px 88px rgba(24, 39, 75, 0.14);
  font-style: normal;
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 140%;
  max-width: 250px;
}
