.content {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
}

.content a {
  text-decoration: none;
}

.illustration {
  display: block;
  margin-top: 72px;
}

.message {
  margin-top: 32px;
  margin-bottom: 10px;
  height: 60px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.header {
  /* font-family: 'Inter'; */
  font-style: normal;
  font-weight: 500;
  font-size: 1.5rem;
  line-height: 1rem;
  text-align: center;
  color: var(--content-active);
}

.caption {
  font-style: normal;
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 160%;
  text-align: center;
  color: var(--content-active);
}

.integrateButton {
  width: 190px;
  height: 42px;
  background: var(--interactive-primary-action);
  border-radius: 6px;
  font-style: normal;
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: var(--neutral-white);
  margin-top: 24px;
}
