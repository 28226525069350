.header {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 25px 24px;
  position: sticky;
  top: 0;
  background-color: var(--interactive-surface-primary);
}

.body {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  background-color: var(--interactive-surface-primary);
  padding: 0 24px;
  height: calc(100% - 90px);
}

.body td {
  cursor: unset;
}

.title {
  font-style: normal;
  font-weight: 500;
  font-size: 1rem;
  line-height: 140%;
  color: var(--neutral-800);
  display: flex;
  gap: 8px;
}

.title svg > path {
  stroke: var(--content-default);
}

.toggleContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}

.toggle {
  padding-right: 8px;
}

/* The negative margin compensates for outside border spacing
 * which otherwise will cause a shift of the header on scroll */
.table {
  margin-top: -10px;
}

.selectedEmployee {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-align: center;
  vertical-align: center;
}

.selectedEmployeeInfo {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.selectedEmployeeName {
  padding-left: 8px;
}

.footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  padding: 24px 16px;
  border-top: 1px solid var(--black-10);
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.primaryButton {
  margin-left: 16px;
}

.emptyTable {
  display: flex;
  height: 230px;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 16px 0;
}
