.container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  padding: 5px;
}

.toastTypeIndicator {
  width: 1px;
  height: 50px;
  border-radius: 10px;
  background-color: var(--content-inverted);
  padding-right: 5px;
}

.info {
  background-color: var(--status-info);
}

.success {
  background-color: var(--green-110);
}

.warning {
  background-color: var(--yellow-100);
}

.error {
  background-color: var(--red-90);
}

.textContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  height: 50px;
  padding: 0 15px;
  text-align: left;
  vertical-align: center;
}

.title {
  padding-bottom: 5px;
  font-size: 0.875rem;
  font-weight: bold;
}

.description {
  font-size: 0.75rem;
}

.description a {
  color: var(--content-inverted);
}

.hidden {
  display: none;
}