:root {
  --black-0: #ffffff;
  --black-5: #f3f3f4;
  --black-10: #e7e7e9;
  --black-20: #ceced4;
  --black-30: #b6b6be;
  --black-40: #9e9ea9;
  --black-50: #858593;
  --black-60: #6d6d7d;
  --black-70: #555568;
  --black-80: #3d3d52;
  --black-90: #24243d;
  --black-100: #0c0c27;
  --black-110: #0b0b23;
  --black-120: #0a0a20;
  --black-130: #09091c;
  --black-140: #080819;
  --black-150: #070715;
  --black-160: #060612;
  --black-170: #04040e;
  --black-180: #03030b;
  --black-190: #020207;
  --black-195: #020206;
  --black-200: #010104;
  --black: var(--black-100);

  --pink-0: #ffffff;
  --pink-5: #fdf5f9;
  --pink-10: #faebf3;
  --pink-20: #f5d7e7;
  --pink-30: #f0c3dc;
  --pink-40: #ebafd0;
  --pink-50: #e79bc4;
  --pink-60: #e288b8;
  --pink-70: #dd74ac;
  --pink-80: #d860a1;
  --pink-90: #d34c95;
  --pink-100: #ce3889;
  --pink-110: #bb337d;
  --pink-120: #a92e70;
  --pink-130: #962964;
  --pink-140: #842458;
  --pink-150: #711f4b;
  --pink-160: #5f1a3f;
  --pink-170: #4c1533;
  --pink-180: #3a1027;
  --pink-190: #280b1a;
  --pink-195: #1e0814;
  --pink-200: #15060e;
  --pink: var(--pink-100);

  --red-0: #ffffff;
  --red-5: #fdf4f6;
  --red-10: #fbeaed;
  --red-20: #f6d5db;
  --red-30: #f2c0c9;
  --red-40: #edabb7;
  --red-50: #e996a4;
  --red-60: #e58192;
  --red-70: #e06c80;
  --red-80: #dc576e;
  --red-90: #d7425c;
  --red-100: #d32d4a;
  --red-110: #c02943;
  --red-120: #ad253d;
  --red-130: #9a2136;
  --red-140: #871d2f;
  --red-150: #741929;
  --red-160: #611522;
  --red-170: #4e111b;
  --red-180: #3b0d15;
  --red-190: #28090e;
  --red-195: #1f070b;
  --red-200: #160508;
  --red: var(--red-100);

  --blue-0: #ffffff;
  --blue-5: #f7f9ff;
  --blue-10: #eff3ff;
  --blue-20: #e0e7ff;
  --blue-30: #d0daff;
  --blue-40: #c1ceff;
  --blue-50: #b1c2ff;
  --blue-60: #a1b6ff;
  --blue-70: #92aaff;
  --blue-80: #829dff;
  --blue-90: #7391ff;
  --blue-100: #6385ff;
  --blue-110: #5a79e8;
  --blue-120: #516dd1;
  --blue-130: #4861ba;
  --blue-140: #3f55a3;
  --blue-150: #37498c;
  --blue-160: #2e3d76;
  --blue-170: #25315f;
  --blue-180: #1c2548;
  --blue-190: #131931;
  --blue-195: #0f1425;
  --blue-200: #0a0e1a;
  --blue: var(--blue-100);

  --green-0: #ffffff;
  --green-5: #f3fcfa;
  --green-10: #e6f9f5;
  --green-20: #cdf2ec;
  --green-30: #b5ece2;
  --green-40: #9ce6d9;
  --green-50: #83dfcf;
  --green-60: #6ad9c6;
  --green-70: #51d3bc;
  --green-80: #39cdb3;
  --green-90: #20c6a9;
  --green-100: #07c0a0;
  --green-110: #06af92;
  --green-120: #069e83;
  --green-130: #058c75;
  --green-140: #047b66;
  --green-150: #046a58;
  --green-160: #03594a;
  --green-170: #03473b;
  --green-180: #02362d;
  --green-190: #01251f;
  --green-195: #011c17;
  --green-200: #011410;
  --green: var(--green-100);

  --yellow-0: #ffffff;
  --yellow-5: #fffcf3;
  --yellow-10: #fff9e8;
  --yellow-20: #fff2d1;
  --yellow-30: #ffecba;
  --yellow-40: #ffe5a3;
  --yellow-50: #ffdf8c;
  --yellow-60: #ffd975;
  --yellow-70: #ffd25e;
  --yellow-80: #ffcc47;
  --yellow-90: #ffc530;
  --yellow-100: #ffbf19;
  --yellow-110: #e8ae17;
  --yellow-120: #d19d14;
  --yellow-130: #ba8b12;
  --yellow-140: #a37a10;
  --yellow-150: #8c690e;
  --yellow-160: #76580c;
  --yellow-170: #5f4709;
  --yellow-180: #483607;
  --yellow-190: #312505;
  --yellow-195: #251c04;
  --yellow-200: #1a1303;
  --yellow: var(--yellow-100);

  --accent-positive: var(--green-110);

  /* Background */
  --background-navigation: var(--black-0);
  --background-sidebar: var(--black-5);
  --background-page: var(--black-0);
  --background-section: var(--black-0);
  --background-dialog: var(--black-0);

  /***********/
  /* Content */
  /***********/
  --content-active: var(--black-100);
  --content-default: var(--black-60);
  --content-muted: var(--black-50);
  --content-disabled: var(--black-30);
  --content-inverted: var(--black-0);
  --content-link: var(--blue-110);

  /***************/
  /* Interactive */
  /***************/
  --interactive-primary-action: var(--pink-100);
  --interactive-primary-hover: var(--pink-110);
  --interactive-primary-pressed: var(--pink-120);
  --interactive-primary-disabled: var(--pink-50);
  --interactive-secondary: var(--green-100);
  /* Surface */
  --interactive-surface-primary: var(--black-0);
  --interactive-surface-default: rgba(109, 109, 125, 0.1);
  --interactive-surface-hover: rgba(109, 109, 125, 0.15);
  --interactive-surface-active: rgba(109, 109, 125, 0.2);
  --interactive-surface-disabled: var(--black-20);
  /* Border */
  --interactive-border-default: var(--black-10);
  --interactive-border-hover: var(--black-30);
  --interactive-border-active: var(--pink-40);
  --interactive-border-focused: var(--pink-40);
  --interactive-border-disabled: var(--black-10);
  --interactive-border-error: var(--red-80);

  /***********/
  /* Neutral */
  /***********/
  --neutral-100: var(--black-10);
  --neutral-200: var(--black-20);
  --neutral-300: var(--black-30);
  --neutral-400: var(--black-40);
  --neutral-500: var(--black-50);
  --neutral-800: var(--black-80);
  --neutral-white: var(--black-0);

  /**********/
  /* Accent */
  /**********/
  --accent-positive: var(--green-110);
  --accent-warning: var(--yellow-100);
  --accent-alert: var(--red-90);

  /**********/
  /* Status */
  /**********/
  --status-neutral-a: var(--black-20);
  --status-neutral-b: var(--black-50);
  --status-info: var(--blue-100);
  --status-low: var(--green-120);
  --status-medium: var(--yellow-90);
  --status-high: var(--red-110);
  --status-critical: var(--red-130);
}
