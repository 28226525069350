.container {
  position: relative;
  width: 240px;
  height: 100vh;
  background: var(--background-sidebar);
  display: flex;
  flex-direction: column;
}

.logo {
  padding: 20px;
}

.navigation {
  color: var(--content-default);
  width: 230px;
  padding: var(--space-xxs) var(--space-sm);
  display: flex;
  flex-direction: column;
  flex: 2;
  gap: 10px;
  overflow-y: auto;
}

.navigation a,
.navigation a:hover {
  text-decoration: none;
  color: var(--content-default);
}

.navigation ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.navigation li > span,
.navigation li > a {
  display: flex;
  flex-direction: row;
  gap: 8px;
  cursor: pointer;
  user-select: none;
  padding: 8px;
  border-radius: 6px;
}

.navigation li > a > svg {
  align-self: center;
}

.expandButton {
  margin-left: auto;
}

.active {
  background-color: var(--interactive-surface-primary);
  border-radius: 6px;
}

.active > a {
  color: var(--interactive-primary-pressed);
}

/**************************/
/* First level navigation */
/**************************/
.navigation > ul > li {
}

/* Empty */
.navigation > ul > li:has(> ul:empty) {
  display: none;
}

.navigation > ul > li > a:hover {
  background-color: var(--interactive-surface-hover);
}

.navigation > ul > li:has(> ul > li.active) > ul {
  display: block;
}

/**************************/
/* Second level navigation */
/**************************/
.navigation > ul > li > ul > li {
  margin-left: 20px;
}

.navigation > ul > li > ul > li > a:hover {
  background-color: var(--interactive-surface-hover);
}

.navigation > ul > li > ul {
  /* Closed by default */
  display: none;
  border-left: 1px solid var(--neutral-200);
  margin-left: 18px;
}

/* Open */
.navigation > ul > li.open > ul {
  display: block;
}

/**************************/
/* Third level navigation */
/**************************/
.navigation > ul > li > ul > li > ul > li > a {
  display: block;
  padding: 4px 0 4px 24px;
}

.navigation > ul > li > ul > li > ul > li > a:hover {
  background-color: var(--interactive-surface-hover);
  border-radius: 6px;
}

.navigation > ul > li > ul > li > ul {
  padding-top: 1px;
  /* Closed by default */
  display: none;
}

/* Open */
.navigation > ul > li > ul > li.open > ul {
  display: block;
}

/* Empty */
.navigation > ul > li > ul > li:has(ul:empty) {
  display: none;
}
