.tooltipContent {
  background-color: var(--neutral-white);
  padding: 16px;
  border-radius: 2px;
  box-shadow:
    0px 8px 28px rgba(24, 39, 75, 0.12),
    0px 18px 88px rgba(24, 39, 75, 0.14);
  font-style: normal;
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 140%;
  z-index: 502; /* This is one more than the sidebar z-index */
  max-width: 250px;
}

.arrow {
  box-shadow:
    0px 8px 28px rgba(24, 39, 75, 0.12),
    0px 18px 88px rgba(24, 39, 75, 0.14);
  position: absolute;
  background-color: var(--neutral-white);
  width: 8px;
  height: 8px;
  transform: rotate(45deg);
}
