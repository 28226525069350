.root {
  position: relative;
  max-width: 100%;
}

.popover {
  width: max-content;
  max-width: calc(100vw - 20px);
  background-color: var(--background-dialog);
  border-radius: 6px;
  box-shadow: 10px 16px 49px rgba(70, 79, 89, 0.2);
  z-index: 20;
  max-height: 1024px;
  overflow-y: auto;
  overflow-x: hidden;
  text-overflow: ellipsis;
}

.input {
  height: 32px;
  border: 1px solid var(--interactive-border-default);
  padding: 4px 0 4px var(--space-sm);
  border-radius: 6px;
  display: flex;
  align-items: center;
  cursor: pointer;
  user-select: none;
  gap: 10px;
  white-space: nowrap;
  font-size: 0.875rem;
  line-height: 140%;
  text-align: center;
  background-color: var(--background-dialog);
}

.input:hover {
  border-color: var(--interactive-border-hover);
}

.input:active {
  border-color: var(--interactive-border-active);
  background-color: var(--interactive-surface-active);
}

.open > .input {
  border-color: var(--interactive-border-active);
  background-color: var(--interactive-surface-active);
}

.input.calendar.iconRight::after {
  content: url(/assets/images/calendar-check.svg);
  height: 26px;
  flex: 2;
  text-align: right;
  margin-right: 10px;
}

.input.downArrow.iconRight::after {
  content: url(/assets/images/select-down.svg);
  height: 20px;
  flex: 2;
  text-align: right;
  margin-right: 10px;
}

.input.rightArrow.iconRight::after {
  content: url(/assets/images/arrow-right.svg);
  height: 16px;
  flex: 2;
  text-align: right;
  margin-right: 10px;
}

.input.plus.iconRight::after {
  content: url(/assets/images/plus.svg);
  height: 26px;
  flex: 2;
  margin-left: -5px;
  margin-top: 4px;
  display: flex;
  align-items: center;
}

.input.check.iconRight::after {
  content: url(/assets/images/check.svg);
  height: 26px;
  flex: 2;
  text-align: right;
  margin-right: 10px;
}

.input.calendar.iconLeft::before {
  content: url(/assets/images/calendar-check.svg);
  height: 26px;
  text-align: left;
  margin-left: 10px;
}

.input.downArrow.iconLeft::before {
  content: url(/assets/images/select-down.svg);
  height: 20px;
  text-align: left;
  margin-left: 10px;
}

.popup {
  margin: 8px 0 0 0;
  padding: 0;
}

.label {
  position: relative;
  display: flex;
  gap: 10px;
  align-items: center;
  overflow: hidden;
}

.disabled {
  opacity: 0.5;
}

.input.iconLeft > .label {
  display: flex;
  gap: 10px;
  padding-left: 0;
}

/****************
 * Primary
 ****************/
.muted {
}

/****************
 * Primary
 ****************/
.primary {
  font-weight: 500;
  border: 1px solid var(--interactive-primary-action);
  background: var(--interactive-primary-action);
  color: var(--content-inverted);
}

.primary:hover {
  border: 1px solid var(--interactive-primary-hover);
  background: var(--interactive-primary-hover);
}

.primary:active {
  border: 1px solid var(--interactive-primary-pressed);
  background: var(--interactive-primary-pressed);
}

.primary:focus {
  outline: 3px solid var(--interactive-border-focused);
  background: var(--interactive-primary-hover);
}

.primary:disabled {
  border: 1px solid var(--interactive-primary-disabled);
  background: var(--interactive-primary-disabled);
}

/****************
 * Secondary
 ****************/
.secondary {
  font-weight: 500;
  border: 1px solid var(--interactive-primary-action);
  background: var(--content-inverted);
  color: var(--interactive-primary-action);
}

.secondary:hover {
  border: 1px solid var(--interactive-primary-hover);
  color: var(--interactive-primary-hover);
}

.secondary:active {
  border: 1px solid var(--interactive-primary-pressed);
  color: var(--interactive-primary-pressed);
}

.secondary:focus {
  outline: 3px solid var(--interactive-surface-default);
}

.secondary:disabled {
  border: 1px solid var(--interactive-primary-disabled);
  color: var(--interactive-primary-disabled);
}

/****************
 * Text
 ****************/
.text {
  justify-content: flex-start;
  font-weight: 400;
  padding: 0;
  border: none;
  background: transparent;
  color: var(--interactive-primary-action);
}

.text:hover {
  color: var(--interactive-primary-hover);
  background: transparent;
}

.text:active {
  color: var(--interactive-primary-pressed);
  background: transparent;
}

.text:focus {
  outline: none;
  background: transparent;
}

.text:disabled {
  color: var(--interactive-primary-disabled);
  background: transparent;
}

/****************
 * Pill
 ****************/
.pill {
  color: var(--content-active);
  font-size: 0.75rem;
  font-weight: 600;
  line-height: 1rem;
  border-radius: 40px;
  background-color: var(--black-10);
}

.pill:hover {
  border: 1px solid var(--interactive-primary-hover);
  color: var(--interactive-primary-hover);
}

.pill:active {
  border: 1px solid var(--interactive-primary-pressed);
  color: var(--interactive-primary-pressed);
  background-color: white;
}

.pill:focus {
  outline: 3px solid var(--interactive-surface-default);
  background-color: white;
}

.pill:disabled {
  border: 1px solid var(--interactive-primary-disabled);
  color: var(--interactive-primary-disabled);
}

/****************
 * Deselected
 ****************/
.deselected {
  font-weight: 400;
  border: 1px solid var(--interactive-border-default);
  background: var(--neutral-white);
  color: var(--content-default);
}

.deselected:hover {
  border: 1px solid var(--interactive-border-hover);
  color: var(--content-active);
}

.deselected:active {
  border: 1px solid var(--interactive-border-active);
  color: var(--content-active);
}

.deselected:focus {
  outline: 3px solid var(--interactive-surface-default);
}

.deselected:disabled {
  color: var(--content-muted);
}

/****************
 * Selected
 ****************/
.selected {
  font-weight: 400;
  border: 1px solid var(--interactive-primary-action);
  background: var(--interactive-surface-default);
  color: var(--content-active);
}

.selected:hover {
  background: var(--interactive-surface-hover);
  color: var(--content-active);
}

.selected:active {
  color: var(--content-active);
}

.selected:active:hover {
  color: var(--content-active);
}

.selected:focus {
  outline: 3px solid var(--interactive-surface-default);
}

.selected:disabled {
  color: var(--content-muted);
}
