.background {
  background: rgba(41, 45, 50, 0.3);
  height: 100vh;
  width: 100vw;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 20;
  overflow: hidden;
}

.root {
  height: 100vh;
  position: absolute;
  right: 0;
  top: 0;
  background-color: var(--background-dialog);
}

.drawer {
  height: 100vh;
  position: absolute;
  right: 0;
  top: 0;
  background-color: var(--background-dialog);
  z-index: 501; /* This is one more than the z-index of the live chat icon */
  overflow: hidden;
}
