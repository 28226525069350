.rootMenu {
  padding: 6px 14px;
  border: none;
  font-size: 0.6875rem;
  font-weight: 400;
  line-height: 130%;
  color: var(--black-60);
  background: none;
  border-radius: 6px;

  border: 1px solid var(--interactive-border-default);

  display: flex;
  gap: 10px;
  padding-left: 20px;
  align-items: center;
  justify-content: space-between;
  overflow: hidden;
}

.rootMenu[data-open],
.rootMenu:hover {
  border: 1px solid var(--interactive-border-hover);
  cursor: pointer;
}
.rootMenu::after {
  display: flex;
  justify-content: flex-end;
  content: url(/assets/images/select-down.svg);
  height: 20px;
  text-align: left;
  margin-left: 10px;
  flex-grow: 10;
}

.menu {
  background: rgba(255, 255, 255, 0.8);
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  border-radius: 6px;
  box-shadow: 10px 16px 49px rgba(70, 79, 89, 0.2);
  outline: 0;
  z-index: 9000;
  overflow-y: scroll;
}

.menuItem {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: none;
  width: 100%;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  text-align: left;
  line-height: 1.8;
  min-width: 110px;
  margin: 0;
  outline: 0;

  user-select: none;
  padding: var(--space-xs) var(--space-sm);
  font-size: 0.6875rem;
  font-weight: 400;
  line-height: 130%;
}

.menuItem:focus {
  background-color: var(--black-10);
  color: var(--black-80);
  cursor: pointer;
}

.menuItem[data-nested][data-open]:not([data-focus-inside]) {
  background-color: var(--black-10);
  color: var(--black-80);
  cursor: pointer;
}

.menuItem[data-focus-inside][data-open] {
  background-color: var(--black-10);
  color: var(--black-80);
}

.tooltip {
  z-index: 50000;
}
