.group {
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: flex-start;

  color: var(--black-60);
  background-color: var(--black-5);

  user-select: none;
  padding: var(--space-xs) var(--space-sm);
  text-transform: uppercase;
  font-size: 0.6875rem;
  font-weight: 400;
  line-height: 130%;
}

.empty {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: var(--space-sm);
}

.empty > span {
  padding: var(--space-xxs);
}

.emptyMessage {
  color: var(--black-30);
}

.menu {
  position: relative;
}

.withSearch {
  padding: var(--space-sm);
  top: 0;
  position: sticky;
  background-color: white;
}

.clearSearch {
  text-decoration: underline;
  cursor: pointer;
}
